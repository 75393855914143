
import { Component, Vue, Watch } from 'vue-property-decorator';
import SvgIcon from '@/components/utilities/SvgIcon.vue';
import { auth } from '@/store/namespaces';
import AuthMethods from '@/store/auth/methods/auth.methods';

@Component({
    components: {
        SvgIcon,
    },
})
export default class NotFound extends Vue {

  link : {name: string} = {name: ''}

    mounted(){
        if (this.isAuthenticated){
            this.link = {name: 'Dashboard'}
        }else this.link = {name: 'Login'}
    }

    @auth.Getter(AuthMethods.getters.GET_AUTHENTICATION)
     isAuthenticated!: boolean;

}
